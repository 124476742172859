<!-- =========================================================================================
    File Name: Home.vue
    Description: Home
========================================================================================== -->

<template>
  <div>
    <vs-divider class="divider">
      <div class="icon-container">
        <vs-icon
          icon-pack="feather"
          icon="icon-briefcase"
          size="medium"
          color="rgb(243, 147, 2)"
        ></vs-icon>
        <h4 class="mt-2 ml-2">
          <strong>{{
            $t(resources.Tourism.i18n) || resources.Tourism.name
          }}</strong>
        </h4>
      </div>
    </vs-divider>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-align="center" vs-w="4" vs-xs="12" class="card-container">
        <vx-card class="tourism-card">
          <div class="img-container">
            <a target="_blank" :href="`https://one2trip.net/${itaCode}`">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/one2trip.png"
                style="height: 40px; cursor: pointer"
                class="imag-blur"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Tú agencia de viajes en línea, vende múltples productos de turismo y
            gana comisiones.<br />
            <a target="_blank" :href="`https://one2trip.net/${itaCode}`">
              <strong class="mt-5">
                {{ $t(resources.Quote.i18n) || resources.Quote.name }}
              </strong>
            </a>
          </p>
        </vx-card>
      </vs-col>
      <vs-col vs-align="center" vs-w="4" vs-xs="12" class="card-container">
        <vx-card class="tourism-card">
          <div class="img-container">
            <a target="_blank" href="https://viveloahora.com/">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/vivelo.png"
                class="imag-blur"
                style="height: 40px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Tu plataforma de beneficios de turismo. Hoteles y paquetes con
            descuento.<br />
            <a target="_blank" :href="`https://viveloahora.com/`" class="mt-3"
              ><strong>
                {{ $t(resources.Quote.i18n) || resources.Quote.name }}</strong
              ></a
            >
          </p>
        </vx-card>
      </vs-col>
      <vs-col vs-align="center" vs-w="4" vs-xs="12" class="card-container">
        <vx-card class="tourism-card">
          <div class="img-container">
            <a target="_blank" :href="`https://pro.one2trip.net/${itaCode}`">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/pro-one2trip.png"
                class="imag-blur"
                style="height: 60px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Plataforma de B2B que ayudará a hacer tus reservas y gestión de las
            mismas de una forma más efectiva.<br />
            <a
              target="_blank"
              :href="`https://pro.one2trip.net/${itaCode}`"
              class="mt-3"
              ><strong>
                {{ $t(resources.Quote.i18n) || resources.Quote.name }}</strong
              ></a
            >
          </p>
        </vx-card>
      </vs-col>
    </vs-row>
    <h6 class="text-primary text-center mb-4">
      {{
        $t(resources.TelegramChannels.i18n) || resources.TelegramChannels.name
      }}
    </h6>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <h4 class="text-warning">
          <strong>
            <a
              target="_blank"
              class="text-warning"
              href="https://t.me/joinchat/R7um6wfOOhw9MBN2"
              >Turismo</a
            ></strong
          >
        </h4>
      </vs-col>
    </vs-row>

    <vs-divider class="divider">
      <div class="icon-container">
        <vs-icon
          icon-pack="feather"
          icon="icon-shield"
          size="medium"
          color="rgb(0, 173, 239)"
        ></vs-icon>
        <h4 class="mt-2 ml-2">
          <strong>Seguros</strong>
        </h4>
      </div>
    </vs-divider>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col
        vs-align="center"
        vs-lg="4"
        vs-sm="6"
        vs-xs="12"
        class="mb-base p-8"
      >
        <vx-card class="insurance-card">
          <div class="img-container">
            <a @click="goSafeguard">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/safeguard.png"
                class="imag-blur ml-4"
                style="height: 50px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Cotiza seguros para: viajeros, estudiantes, telemedicina y
            asistencia funeraria a nivel global.<br />
            <a @click="goSafeguard"
              ><strong>
                {{ $t(resources.Quote.i18n) || resources.Quote.name }}</strong
              ></a
            >
          </p>
        </vx-card>
      </vs-col>

      <vs-col
        vs-align="center"
        vs-lg="4"
        vs-sm="6"
        vs-xs="12"
        class="mb-base p-8"
      >
        <vx-card class="insurance-card">
          <div class="img-container">
            <a @click="goVumi">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/vumi.png"
                class="imag-blur  ml-6"
                style="height: 50px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Agencia de seguros, en el momento adecuado y comprometidos a
            ayudarlo.<br />
            <a @click="goVumiHealth"
              ><strong>
                {{
                  $t(resources.QuoteHealth.i18n) || resources.QuoteHealth.name
                }}</strong
              ></a
            >
            <br />
            <a @click="goVumiTraveler"
              ><strong>
                {{
                  $t(resources.QuoteTraveler.i18n) ||
                    resources.QuoteTraveler.name
                }}</strong
              ></a
            >
            <br />
          </p>
        </vx-card>
      </vs-col>
      <vs-col
        vs-align="center"
        vs-lg="4"
        vs-sm="6"
        vs-xs="12"
        class="mb-base p-8"
        v-if="itaStatus == '1'"
      >
        <vx-card class="insurance-card">
          <div class="img-container">
            <a @click="goSegurosCaracas">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/seguroscaracas.png"
                class="imag-blur"
                style="height: 75px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Cotiza seguros de salud individual para residentes en Venezuela.<br />
            <a @click="goSegurosCaracas"
              ><strong>
                {{ $t(resources.Quote.i18n) || resources.Quote.name }}</strong
              ></a
            >
          </p>
        </vx-card>
      </vs-col>
      <vs-col
        vs-align="center"
        vs-lg="4"
        vs-sm="6"
        vs-xs="12"
        class="mb-base p-8"
        v-if="itaStatus == '1'"
      >
        <vx-card class="insurance-card">
          <div class="img-container">
            <a>
              <img
                src="https://cdn1.visiontravel.net/Images/industries/mercantil.png"
                class="imag-blur"
                style="height: 75px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Cotiza seguros de salud para residentes en Venezuela.<br />
            <a
              @click="
                downLoadFile(
                  'cotizador-planes-global-benefits-venezuela.xlsm',
                  'cotizador-planes-global-benefits-venezuela'
                )
              "
              ><strong>
                {{
                  $t(resources.QuoteMercantilVenezuela.i18n) ||
                    resources.QuoteMercantilVenezuela.name
                }}</strong
              >
            </a>
            <br />
            <a
              @click="
                downLoadFile(
                  'cotizadores-group-benefits-panamá-V47.xlsm',
                  'cotizadores-group-benefits-panamá-V47'
                )
              "
              ><strong>
                {{
                  $t(resources.QuoteMercantilPanama.i18n) ||
                    resources.QuoteMercantilPanama.name
                }}</strong
              ></a
            >
            <br />
            <a
              @click="
                downLoadFile(
                  'solicitud-poliza-seguro.pdf',
                  'solicitud-poliza-seguro'
                )
              "
              ><strong>
                {{
                  $t(resources.QuoteMercantilRequest.i18n) ||
                    resources.QuoteMercantilRequest.name
                }}</strong
              ></a
            >
            <br />
          </p>
        </vx-card>
      </vs-col>
      <vs-col
        vs-align="center"
        vs-lg="4"
        vs-sm="6"
        vs-xs="12"
        class="mb-base p-8"
        v-if="itaStatus == '1'"
      >
        <vx-card class="insurance-card">
          <div class="img-container">
            <a>
              <img
                src="https://cdn1.visiontravel.net/Images/industries/logo.png"
                class="imag-blur"
                style="height: 75px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-4">
            Cotiza seguros de salud para residentes en Venezuela.<br />
            <a
              @click="
                downLoadFile(
                  'cotizador-la-internacional.xlsm',
                  'cotizador-la-internacional'
                )
              "
              ><strong>
                {{
                  $t(resources.Quote.i18n) ||
                    resources.Quote.name
                }}</strong
              >
            </a>
            <br/>
            <a
              @click="
                downLoadFile(
                  'cotizador-poliza-años-plateados.xlsm',
                  'cotizador-poliza-años-plateados'
                )
              "
              ><strong>
                {{
                  $t(resources.QuoteMercantilSilverYears.i18n) ||
                    resources.QuoteMercantilSilverYears.name
                }}</strong
              ></a
            >
          </p>
        </vx-card>
      </vs-col>
      <vs-col
        vs-align="center"
        vs-lg="4"
        vs-sm="6"
        vs-xs="12"
        class="mb-base p-8"
      >
        <vx-card>
          <div class="img-container">
            <img
              src="https://cdn1.visiontravel.net/Images/industries/bee-insurance.png"
              class="imag-blur  ml-4"
              style="height: 75px; cursor: pointer"
            />
          </div>
          <p class="text-center">
            Seguro de vida internacional diseñado para ti y tu familia.<br />
          </p>
          <div class="be-container">
            <a @click="goBee"
              ><strong>
                {{ $t(resources.Quote.i18n) || resources.Quote.name }}</strong
              ></a
            >
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
    <h6 class="text-primary text-center mb-4 mt-base">
      <strong>{{
        $t(resources.TelegramChannels.i18n) || resources.TelegramChannels.name
      }}</strong>
    </h6>
    <vs-row vs-type="flex" vs-justify="center" vs-w="12">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" class="mb-4">
        <h4 style="color: rgb(0, 173, 239);">
          <strong>
            <a
              style="color: rgb(0, 173, 239);"
              target="_blank"
              href="https://t.me/safeguardtraining"
              >Training</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(0, 173, 239);"
              target="_blank"
              href="https://t.me/safeguardbyvisionbiz"
              >Showroom</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(0, 173, 239);"
              target="_blank"
              href="https://t.me/joinchat/AAAAAFXUFCmXhdVjKKmO4g"
              >BSP</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(0, 173, 239);"
              target="_blank"
              href="https://t.me/joinchat/SrpAxgBfA_3Gxf78"
              >VUMI</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(0, 173, 239);"
              target="_blank"
              href="https://t.me/joinchat/5Bi6CwWbJOk3MzIx"
              >Redbridge</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(0, 173, 239);"
              target="_blank"
              href="https://t.me/joinchat/tY28hT_AEfsyZmMx"
              >Seguros Caracas</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(0, 173, 239);"
              target="_blank"
              href="https://t.me/joinchat/qtaKzXiq0XkzN2Nh"
              >Thalo</a
            ></strong
          >
        </h4>
      </vs-col>
    </vs-row>
    <vs-divider class="divider">
      <div class="icon-container">
        <feather-icon icon="KeyIcon" svgClasses="h-9 w-9" color="success" />
        <h4 class="mt-2 ml-2">
          <strong>Inmobiliaria</strong>
        </h4>
      </div>
    </vs-divider>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-align="center" vs-w="6" vs-xs="12" class="mb-4 mt-4">
        <vx-card>
          <div class="img-container">
            <a @click="goRealVision">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/realvision.png"
                class="imag-blur"
                style="height: 20px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-5">
            Cientos de bienes raíces, ya sea para; venta, renta o
            transferencia.<br />
            <a @click="goRealVision"
              ><strong>
                {{ $t(resources.Quote.i18n) || resources.Quote.name }}</strong
              ></a
            >
            |
            <a @click="goRealVisionCert"
              ><strong>
                {{
                  $t(resources.Certification.i18n) ||
                    resources.Certification.name
                }}</strong
              ></a
            >
          </p>
        </vx-card>
      </vs-col>
    </vs-row>
    <h6 class="text-primary text-center mb-4 mt-base">
      {{
        $t(resources.TelegramChannels.i18n) || resources.TelegramChannels.name
      }}
    </h6>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        class="mb-4 p-8"
      >
        <h4 style="color: rgb(37,45,81);">
          <strong>
            <a
              style="color: rgb(37,45,81);"
              target="_blank"
              href="https://t.me/realvisiontraining"
              >Training</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(37,45,81);"
              target="_blank"
              href="https://t.me/joinchat/U-4IgJd4yQ5KSDYK"
              >Showroom</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(37,45,81);"
              target="_blank"
              href="https://t.me/joinchat/TFlPZdGoUGs_riZr"
              >Búsqueda</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(37,45,81);"
              target="_blank"
              href="https://t.me/joinchat/SyGTTyndcEiQINS_"
              >Oferta</a
            >&nbsp;|&nbsp;
            <a
              style="color: rgb(37,45,81);"
              target="_blank"
              href="https://t.me/joinchat/UxlNk6Ka9g3XD6iK"
              >Cierre</a
            ></strong
          >
        </h4>
      </vs-col>
    </vs-row>

    <vs-divider class="divider">
      <div class="icon-container">
        <vs-icon
          icon-pack="feather"
          icon="icon-user"
          size="medium"
          color="primary"
        ></vs-icon>
        <h4 class="mt-2 ml-2">
          <strong>{{
            $t(resources.CustomerService.i18n) || resources.CustomerService.name
          }}</strong>
        </h4>
      </div>
    </vs-divider>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-align="center" vs-w="6" vs-xs="12" class="mb-4 mt-4">
        <vx-card>
          <div class="img-container">
            <a
              target="_blank"
              :href="`https://corebos.visiontravel.net/cportal/index.php`"
            >
              <img
                src="https://cdn1.visiontravel.net/Images/industries/customer-service.png"
                class="imag-blur"
                style="height: 70px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-5">
            Accede a nuestro portal del cliente.<br />
            Si es la primera vez y aun no has activado tu cuenta en corebos,
            envía un correo a
            <strong>servicioalcliente@visiontravel.net</strong> con el asunto:
            "Solicitud de activación de corebos" e indicando tu código IBO.<br />
            <a
              target="_blank"
              :href="`https://corebos.visiontravel.net/cportal/index.php`"
              ><strong> {{ $t(resources.Go.i18n) || resources.Go.name }}</strong
              >-></a
            >
          </p>
        </vx-card>
      </vs-col>
    </vs-row>

    <vs-divider class="divider">
      <div class="icon-container">
        <vs-icon
          icon-pack="feather"
          icon="icon-book"
          size="medium"
          color="success"
        ></vs-icon>
        <h4 class="mt-2 ml-2">
          <strong>{{
            $t(resources.Education.i18n) || resources.Education.name
          }}</strong>
        </h4>
      </div>
    </vs-divider>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-align="center" vs-w="6" vs-xs="12" class="mb-4 mt-4">
        <vx-card>
          <div class="img-container">
            <a target="_blank" :href="`https://visionbizacademy.com`">
              <img
                src="https://cdn1.visiontravel.net/Images/industries/academy.png"
                class="imag-blur"
                style="height: 50px; cursor: pointer"
              />
            </a>
          </div>
          <p class="text-center mt-5">
            Accede a nuestra Academia.<br />&nbsp; Como IBO tienes acceso a
            nuestros cursos de forma gratuita<br />
            <a target="_blank" :href="`https://visionbizacademy.com`"
              ><strong> {{ $t(resources.Go.i18n) || resources.Go.name }}</strong
              >-></a
            >
          </p>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import alerts from "@/i18n/alerts.js";

export default {
  components: {},
  data() {
    return {
      resources: resources,
      alerts: alerts,
      database: database,
      itaStatus: localStorage.getItem("status"),
      name: JSON.parse(localStorage.getItem("userInfo")).name,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode
    };
  },
  created: async function() {},
  computed: {},
  methods: {
    goChatCenter() {
      const _url = `https://api.whatsapp.com/send?phone=13055138760`;
      window.open(_url, "_blank");
    },

    goSafeguard() {
      const _url = `https://www.safeguardshop.net/`;
      window.open(_url, "_blank");
    },

    goBSP() {
      const _url = `https://www.bsprevision.net/Afiliacion/Index/111001${this.itaCode}`;
      window.open(_url, "_blank");
    },

    goVumiHealth() {
      const _url = `https://agentsportal.vumigroup.com/index.php/vumi_agent/index/280342`;
      window.open(_url, "_blank");
    },

    goVumiTraveler() {
      const _url = `https://agentsportal.vumigroup.com/index.php/travel/index/280342`;
      window.open(_url, "_blank");
    },

    goVumiCert() {
      const _url = `http://www.vipbluegroup.com/visionbiz`;
      window.open(_url, "_blank");
    },

    goRedBridge() {
      const _url = `https://t.me/joinchat/5Bi6CwWbJOk3MzIx`;
      window.open(_url, "_blank");
    },

    goRealVision() {
      const _url = `https://realvision.estate/`;
      window.open(_url, "_blank");
    },

    goRealVisionCert() {
      const _url = `https://visionbizacademy.com/`;
      window.open(_url, "_blank");
    },

    goSegurosCaracas() {
      let _tokenVT = localStorage.getItem("tokenVT");
      let _ita = this.itaCode;
      const _url = `https://sasweb-corp.seguroscaracas.com/extranetgeneralvista/Pag_Ext_Autenticacion.xhtml?codigoVT=${_ita}&tokenVT=${_tokenVT}`;
      window.open(_url, "_blank");
    },

    goBee() {
      const _url = `https://admin.bee-insurance.com/login_access/visionbiz/${this.itaCode}/`;
      window.open(_url, "_blank");
    },

    downLoadFile(path, name) {
      const downLoadInstance = document.createElement("a");
      downLoadInstance.href = `https://cdn1.visiontravel.net/Resources/industries/${path}`;
      downLoadInstance.target = "_blank";
      downLoadInstance.download = name;

      document.body.appendChild(downLoadInstance);
      downLoadInstance.click();
      document.body.removeChild(downLoadInstance);
    }
  }
};
</script>

<style lang="scss">
.img-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.icon-container {
  display: flex;
  background-color: #f7f7f7;
}

.be-container {
  margin-top: 5px;
  display: flex;
  justify-content: center;

  a {
    font-weight: bold;
  }
}

.card-container {
  margin-top: 20px;
  padding: 20px;
}

.tourism-card {
  height: 190px;
}

.insurance-card {
  height: 230px;
}

.divider {
  margin-top: 30px;
  span {
    padding: 0;
  }
}

a {
  cursor: pointer;
}
</style>
