var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vs-divider", { staticClass: "divider" }, [
        _c(
          "div",
          { staticClass: "icon-container" },
          [
            _c("vs-icon", {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-briefcase",
                size: "medium",
                color: "rgb(243, 147, 2)"
              }
            }),
            _c("h4", { staticClass: "mt-2 ml-2" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Tourism.i18n) ||
                      _vm.resources.Tourism.name
                  )
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "card-container",
              attrs: { "vs-align": "center", "vs-w": "4", "vs-xs": "12" }
            },
            [
              _c("vx-card", { staticClass: "tourism-card" }, [
                _c("div", { staticClass: "img-container" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://one2trip.net/" + _vm.itaCode
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "imag-blur",
                        staticStyle: { height: "40px", cursor: "pointer" },
                        attrs: {
                          src:
                            "https://cdn1.visiontravel.net/Images/industries/one2trip.png"
                        }
                      })
                    ]
                  )
                ]),
                _c("p", { staticClass: "text-center mt-4" }, [
                  _vm._v(
                    "\n          Tú agencia de viajes en línea, vende múltples productos de turismo y\n          gana comisiones."
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://one2trip.net/" + _vm.itaCode
                      }
                    },
                    [
                      _c("strong", { staticClass: "mt-5" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Quote.i18n) ||
                                _vm.resources.Quote.name
                            ) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "card-container",
              attrs: { "vs-align": "center", "vs-w": "4", "vs-xs": "12" }
            },
            [
              _c("vx-card", { staticClass: "tourism-card" }, [
                _c("div", { staticClass: "img-container" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://viveloahora.com/"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "imag-blur",
                        staticStyle: { height: "40px", cursor: "pointer" },
                        attrs: {
                          src:
                            "https://cdn1.visiontravel.net/Images/industries/vivelo.png"
                        }
                      })
                    ]
                  )
                ]),
                _c("p", { staticClass: "text-center mt-4" }, [
                  _vm._v(
                    "\n          Tu plataforma de beneficios de turismo. Hoteles y paquetes con\n          descuento."
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        target: "_blank",
                        href: "https://viveloahora.com/"
                      }
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Quote.i18n) ||
                                _vm.resources.Quote.name
                            )
                        )
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "card-container",
              attrs: { "vs-align": "center", "vs-w": "4", "vs-xs": "12" }
            },
            [
              _c("vx-card", { staticClass: "tourism-card" }, [
                _c("div", { staticClass: "img-container" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://pro.one2trip.net/" + _vm.itaCode
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "imag-blur",
                        staticStyle: { height: "60px", cursor: "pointer" },
                        attrs: {
                          src:
                            "https://cdn1.visiontravel.net/Images/industries/pro-one2trip.png"
                        }
                      })
                    ]
                  )
                ]),
                _c("p", { staticClass: "text-center mt-4" }, [
                  _vm._v(
                    "\n          Plataforma de B2B que ayudará a hacer tus reservas y gestión de las\n          mismas de una forma más efectiva."
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        target: "_blank",
                        href: "https://pro.one2trip.net/" + _vm.itaCode
                      }
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Quote.i18n) ||
                                _vm.resources.Quote.name
                            )
                        )
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("h6", { staticClass: "text-primary text-center mb-4" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(_vm.resources.TelegramChannels.i18n) ||
                _vm.resources.TelegramChannels.name
            ) +
            "\n  "
        )
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "4"
              }
            },
            [
              _c("h4", { staticClass: "text-warning" }, [
                _c("strong", [
                  _c(
                    "a",
                    {
                      staticClass: "text-warning",
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/R7um6wfOOhw9MBN2"
                      }
                    },
                    [_vm._v("Turismo")]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c("vs-divider", { staticClass: "divider" }, [
        _c(
          "div",
          { staticClass: "icon-container" },
          [
            _c("vs-icon", {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-shield",
                size: "medium",
                color: "rgb(0, 173, 239)"
              }
            }),
            _c("h4", { staticClass: "mt-2 ml-2" }, [
              _c("strong", [_vm._v("Seguros")])
            ])
          ],
          1
        )
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "mb-base p-8",
              attrs: {
                "vs-align": "center",
                "vs-lg": "4",
                "vs-sm": "6",
                "vs-xs": "12"
              }
            },
            [
              _c("vx-card", { staticClass: "insurance-card" }, [
                _c("div", { staticClass: "img-container" }, [
                  _c("a", { on: { click: _vm.goSafeguard } }, [
                    _c("img", {
                      staticClass: "imag-blur ml-4",
                      staticStyle: { height: "50px", cursor: "pointer" },
                      attrs: {
                        src:
                          "https://cdn1.visiontravel.net/Images/industries/safeguard.png"
                      }
                    })
                  ])
                ]),
                _c("p", { staticClass: "text-center mt-4" }, [
                  _vm._v(
                    "\n          Cotiza seguros para: viajeros, estudiantes, telemedicina y\n          asistencia funeraria a nivel global."
                  ),
                  _c("br"),
                  _c("a", { on: { click: _vm.goSafeguard } }, [
                    _c("strong", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(_vm.resources.Quote.i18n) ||
                              _vm.resources.Quote.name
                          )
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "mb-base p-8",
              attrs: {
                "vs-align": "center",
                "vs-lg": "4",
                "vs-sm": "6",
                "vs-xs": "12"
              }
            },
            [
              _c("vx-card", { staticClass: "insurance-card" }, [
                _c("div", { staticClass: "img-container" }, [
                  _c("a", { on: { click: _vm.goVumi } }, [
                    _c("img", {
                      staticClass: "imag-blur  ml-6",
                      staticStyle: { height: "50px", cursor: "pointer" },
                      attrs: {
                        src:
                          "https://cdn1.visiontravel.net/Images/industries/vumi.png"
                      }
                    })
                  ])
                ]),
                _c("p", { staticClass: "text-center mt-4" }, [
                  _vm._v(
                    "\n          Agencia de seguros, en el momento adecuado y comprometidos a\n          ayudarlo."
                  ),
                  _c("br"),
                  _c("a", { on: { click: _vm.goVumiHealth } }, [
                    _c("strong", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(_vm.resources.QuoteHealth.i18n) ||
                              _vm.resources.QuoteHealth.name
                          )
                      )
                    ])
                  ]),
                  _c("br"),
                  _c("a", { on: { click: _vm.goVumiTraveler } }, [
                    _c("strong", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(_vm.resources.QuoteTraveler.i18n) ||
                              _vm.resources.QuoteTraveler.name
                          )
                      )
                    ])
                  ]),
                  _c("br")
                ])
              ])
            ],
            1
          ),
          _vm.itaStatus == "1"
            ? _c(
                "vs-col",
                {
                  staticClass: "mb-base p-8",
                  attrs: {
                    "vs-align": "center",
                    "vs-lg": "4",
                    "vs-sm": "6",
                    "vs-xs": "12"
                  }
                },
                [
                  _c("vx-card", { staticClass: "insurance-card" }, [
                    _c("div", { staticClass: "img-container" }, [
                      _c("a", { on: { click: _vm.goSegurosCaracas } }, [
                        _c("img", {
                          staticClass: "imag-blur",
                          staticStyle: { height: "75px", cursor: "pointer" },
                          attrs: {
                            src:
                              "https://cdn1.visiontravel.net/Images/industries/seguroscaracas.png"
                          }
                        })
                      ])
                    ]),
                    _c("p", { staticClass: "text-center mt-4" }, [
                      _vm._v(
                        "\n          Cotiza seguros de salud individual para residentes en Venezuela."
                      ),
                      _c("br"),
                      _c("a", { on: { click: _vm.goSegurosCaracas } }, [
                        _c("strong", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.Quote.i18n) ||
                                  _vm.resources.Quote.name
                              )
                          )
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.itaStatus == "1"
            ? _c(
                "vs-col",
                {
                  staticClass: "mb-base p-8",
                  attrs: {
                    "vs-align": "center",
                    "vs-lg": "4",
                    "vs-sm": "6",
                    "vs-xs": "12"
                  }
                },
                [
                  _c("vx-card", { staticClass: "insurance-card" }, [
                    _c("div", { staticClass: "img-container" }, [
                      _c("a", [
                        _c("img", {
                          staticClass: "imag-blur",
                          staticStyle: { height: "75px", cursor: "pointer" },
                          attrs: {
                            src:
                              "https://cdn1.visiontravel.net/Images/industries/mercantil.png"
                          }
                        })
                      ])
                    ]),
                    _c("p", { staticClass: "text-center mt-4" }, [
                      _vm._v(
                        "\n          Cotiza seguros de salud para residentes en Venezuela."
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.downLoadFile(
                                "cotizador-planes-global-benefits-venezuela.xlsm",
                                "cotizador-planes-global-benefits-venezuela"
                              )
                            }
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.resources.QuoteMercantilVenezuela.i18n
                                  ) ||
                                    _vm.resources.QuoteMercantilVenezuela.name
                                )
                            )
                          ])
                        ]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.downLoadFile(
                                "cotizadores-group-benefits-panamá-V47.xlsm",
                                "cotizadores-group-benefits-panamá-V47"
                              )
                            }
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.resources.QuoteMercantilPanama.i18n
                                  ) || _vm.resources.QuoteMercantilPanama.name
                                )
                            )
                          ])
                        ]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.downLoadFile(
                                "solicitud-poliza-seguro.pdf",
                                "solicitud-poliza-seguro"
                              )
                            }
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.resources.QuoteMercantilRequest.i18n
                                  ) || _vm.resources.QuoteMercantilRequest.name
                                )
                            )
                          ])
                        ]
                      ),
                      _c("br")
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.itaStatus == "1"
            ? _c(
                "vs-col",
                {
                  staticClass: "mb-base p-8",
                  attrs: {
                    "vs-align": "center",
                    "vs-lg": "4",
                    "vs-sm": "6",
                    "vs-xs": "12"
                  }
                },
                [
                  _c("vx-card", { staticClass: "insurance-card" }, [
                    _c("div", { staticClass: "img-container" }, [
                      _c("a", [
                        _c("img", {
                          staticClass: "imag-blur",
                          staticStyle: { height: "75px", cursor: "pointer" },
                          attrs: {
                            src:
                              "https://cdn1.visiontravel.net/Images/industries/logo.png"
                          }
                        })
                      ])
                    ]),
                    _c("p", { staticClass: "text-center mt-4" }, [
                      _vm._v(
                        "\n          Cotiza seguros de salud para residentes en Venezuela."
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.downLoadFile(
                                "cotizador-la-internacional.xlsm",
                                "cotizador-la-internacional"
                              )
                            }
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(_vm.resources.Quote.i18n) ||
                                    _vm.resources.Quote.name
                                )
                            )
                          ])
                        ]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.downLoadFile(
                                "cotizador-poliza-años-plateados.xlsm",
                                "cotizador-poliza-años-plateados"
                              )
                            }
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.resources.QuoteMercantilSilverYears.i18n
                                  ) ||
                                    _vm.resources.QuoteMercantilSilverYears.name
                                )
                            )
                          ])
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-col",
            {
              staticClass: "mb-base p-8",
              attrs: {
                "vs-align": "center",
                "vs-lg": "4",
                "vs-sm": "6",
                "vs-xs": "12"
              }
            },
            [
              _c("vx-card", [
                _c("div", { staticClass: "img-container" }, [
                  _c("img", {
                    staticClass: "imag-blur  ml-4",
                    staticStyle: { height: "75px", cursor: "pointer" },
                    attrs: {
                      src:
                        "https://cdn1.visiontravel.net/Images/industries/bee-insurance.png"
                    }
                  })
                ]),
                _c("p", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n          Seguro de vida internacional diseñado para ti y tu familia."
                  ),
                  _c("br")
                ]),
                _c("div", { staticClass: "be-container" }, [
                  _c("a", { on: { click: _vm.goBee } }, [
                    _c("strong", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(_vm.resources.Quote.i18n) ||
                              _vm.resources.Quote.name
                          )
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("h6", { staticClass: "text-primary text-center mb-4 mt-base" }, [
        _c("strong", [
          _vm._v(
            _vm._s(
              _vm.$t(_vm.resources.TelegramChannels.i18n) ||
                _vm.resources.TelegramChannels.name
            )
          )
        ])
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center", "vs-w": "12" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "mb-4",
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center"
              }
            },
            [
              _c("h4", { staticStyle: { color: "rgb(0, 173, 239)" } }, [
                _c("strong", [
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(0, 173, 239)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/safeguardtraining"
                      }
                    },
                    [_vm._v("Training")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(0, 173, 239)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/safeguardbyvisionbiz"
                      }
                    },
                    [_vm._v("Showroom")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(0, 173, 239)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/AAAAAFXUFCmXhdVjKKmO4g"
                      }
                    },
                    [_vm._v("BSP")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(0, 173, 239)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/SrpAxgBfA_3Gxf78"
                      }
                    },
                    [_vm._v("VUMI")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(0, 173, 239)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/5Bi6CwWbJOk3MzIx"
                      }
                    },
                    [_vm._v("Redbridge")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(0, 173, 239)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/tY28hT_AEfsyZmMx"
                      }
                    },
                    [_vm._v("Seguros Caracas")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(0, 173, 239)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/qtaKzXiq0XkzN2Nh"
                      }
                    },
                    [_vm._v("Thalo")]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c("vs-divider", { staticClass: "divider" }, [
        _c(
          "div",
          { staticClass: "icon-container" },
          [
            _c("feather-icon", {
              attrs: {
                icon: "KeyIcon",
                svgClasses: "h-9 w-9",
                color: "success"
              }
            }),
            _c("h4", { staticClass: "mt-2 ml-2" }, [
              _c("strong", [_vm._v("Inmobiliaria")])
            ])
          ],
          1
        )
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "mb-4 mt-4",
              attrs: { "vs-align": "center", "vs-w": "6", "vs-xs": "12" }
            },
            [
              _c("vx-card", [
                _c("div", { staticClass: "img-container" }, [
                  _c("a", { on: { click: _vm.goRealVision } }, [
                    _c("img", {
                      staticClass: "imag-blur",
                      staticStyle: { height: "20px", cursor: "pointer" },
                      attrs: {
                        src:
                          "https://cdn1.visiontravel.net/Images/industries/realvision.png"
                      }
                    })
                  ])
                ]),
                _c("p", { staticClass: "text-center mt-5" }, [
                  _vm._v(
                    "\n          Cientos de bienes raíces, ya sea para; venta, renta o\n          transferencia."
                  ),
                  _c("br"),
                  _c("a", { on: { click: _vm.goRealVision } }, [
                    _c("strong", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(_vm.resources.Quote.i18n) ||
                              _vm.resources.Quote.name
                          )
                      )
                    ])
                  ]),
                  _vm._v("\n          |\n          "),
                  _c("a", { on: { click: _vm.goRealVisionCert } }, [
                    _c("strong", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(_vm.resources.Certification.i18n) ||
                              _vm.resources.Certification.name
                          )
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("h6", { staticClass: "text-primary text-center mb-4 mt-base" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(_vm.resources.TelegramChannels.i18n) ||
                _vm.resources.TelegramChannels.name
            ) +
            "\n  "
        )
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "mb-4 p-8",
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center"
              }
            },
            [
              _c("h4", { staticStyle: { color: "rgb(37,45,81)" } }, [
                _c("strong", [
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(37,45,81)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/realvisiontraining"
                      }
                    },
                    [_vm._v("Training")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(37,45,81)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/U-4IgJd4yQ5KSDYK"
                      }
                    },
                    [_vm._v("Showroom")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(37,45,81)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/TFlPZdGoUGs_riZr"
                      }
                    },
                    [_vm._v("Búsqueda")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(37,45,81)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/SyGTTyndcEiQINS_"
                      }
                    },
                    [_vm._v("Oferta")]
                  ),
                  _vm._v(" | \n          "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "rgb(37,45,81)" },
                      attrs: {
                        target: "_blank",
                        href: "https://t.me/joinchat/UxlNk6Ka9g3XD6iK"
                      }
                    },
                    [_vm._v("Cierre")]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c("vs-divider", { staticClass: "divider" }, [
        _c(
          "div",
          { staticClass: "icon-container" },
          [
            _c("vs-icon", {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-user",
                size: "medium",
                color: "primary"
              }
            }),
            _c("h4", { staticClass: "mt-2 ml-2" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.CustomerService.i18n) ||
                      _vm.resources.CustomerService.name
                  )
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "mb-4 mt-4",
              attrs: { "vs-align": "center", "vs-w": "6", "vs-xs": "12" }
            },
            [
              _c("vx-card", [
                _c("div", { staticClass: "img-container" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href:
                          "https://corebos.visiontravel.net/cportal/index.php"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "imag-blur",
                        staticStyle: { height: "70px", cursor: "pointer" },
                        attrs: {
                          src:
                            "https://cdn1.visiontravel.net/Images/industries/customer-service.png"
                        }
                      })
                    ]
                  )
                ]),
                _c("p", { staticClass: "text-center mt-5" }, [
                  _vm._v("\n          Accede a nuestro portal del cliente."),
                  _c("br"),
                  _vm._v(
                    "\n          Si es la primera vez y aun no has activado tu cuenta en corebos,\n          envía un correo a\n          "
                  ),
                  _c("strong", [_vm._v("servicioalcliente@visiontravel.net")]),
                  _vm._v(
                    ' con el asunto:\n          "Solicitud de activación de corebos" e indicando tu código IBO.'
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href:
                          "https://corebos.visiontravel.net/cportal/index.php"
                      }
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.Go.i18n) ||
                                _vm.resources.Go.name
                            )
                        )
                      ]),
                      _vm._v("->")
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("vs-divider", { staticClass: "divider" }, [
        _c(
          "div",
          { staticClass: "icon-container" },
          [
            _c("vs-icon", {
              attrs: {
                "icon-pack": "feather",
                icon: "icon-book",
                size: "medium",
                color: "success"
              }
            }),
            _c("h4", { staticClass: "mt-2 ml-2" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Education.i18n) ||
                      _vm.resources.Education.name
                  )
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-col",
            {
              staticClass: "mb-4 mt-4",
              attrs: { "vs-align": "center", "vs-w": "6", "vs-xs": "12" }
            },
            [
              _c("vx-card", [
                _c("div", { staticClass: "img-container" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://visionbizacademy.com"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "imag-blur",
                        staticStyle: { height: "50px", cursor: "pointer" },
                        attrs: {
                          src:
                            "https://cdn1.visiontravel.net/Images/industries/academy.png"
                        }
                      })
                    ]
                  )
                ]),
                _c("p", { staticClass: "text-center mt-5" }, [
                  _vm._v("\n          Accede a nuestra Academia."),
                  _c("br"),
                  _vm._v(
                    "  Como IBO tienes acceso a\n          nuestros cursos de forma gratuita"
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://visionbizacademy.com"
                      }
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.Go.i18n) ||
                                _vm.resources.Go.name
                            )
                        )
                      ]),
                      _vm._v("->")
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }